import React, { Fragment } from 'react';
import Head from 'next/head';

type IUseGoogleTagManager = (options: { gtmId: string; gtmAuth: string; gtmPreview: string }) => {
  render: () => React.ReactNode;
};

export const useGoogleTagManager: IUseGoogleTagManager = (option) => {
  const renderGoogleTagManager = () => (
    <Fragment>
      {/* https://github.com/zeit/next.js/issues/160#issuecomment-266839584 */}
      <Head>
        {/* eslint-disable-next-line @next/next/next-script-for-ga */}
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${option.gtmAuth}&gtm_preview=${option.gtmPreview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${option.gtmId}');`,
          }}
        />
      </Head>
      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${option.gtmId}&gtm_auth=${option.gtmAuth}&gtm_preview=${option.gtmPreview}&gtm_cookies_win=x"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
        }}
      />
    </Fragment>
  );

  return {
    render: renderGoogleTagManager,
  };
};
