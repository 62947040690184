import React from 'react';
import { alignCss } from '~/styles/alignCss';
import CharacterMaintenance from '~/assets/logo/character-maintenance.svg';

export type Props = {};

export const MESSAGE = {
  ERROR_MESSAGE: '',
  BACK: '戻る',
} as const;

export const MaintenancePage: React.FC<Props> = () => (
  <div css={alignCss({ align: 'center' })}>
    <img src={CharacterMaintenance} alt="" width={'120px'} />

    <div css={alignCss({ align: 'center', mt: 3 })}>現在、メンテナンス中です。</div>
    <div css={alignCss({ align: 'center', mt: 3 })}>
      <div css={alignCss({ align: 'center', mb: 1 })}>ご不便をおかけしますが、</div>
      <div>今しばらくお待ちください。</div>
    </div>
  </div>
);
