import { css } from '@emotion/react';
import { boxShadowStyles } from './boxShadowStyles';
import { componentTokens } from './designToken';
import { linkColor, linkBehabior } from './linkStyle';
import { mediaQueries } from './mediaQueries';
import { textCss } from './textCss';

export const global = css`
  body,
  pre {
    font-family: 'Helvetica Neue', Helvetica, 'Segoe UI', 'Hiragino Sans', 'メイリオ',
      'Meiryo, Osaka', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
    ${textCss({ size: 'm', weight: 'regular' })}
  }

  html,
  body,
  /* Next.js */
  #__next,
  /* Storybook */
  #root {
    height: 100%;
  }

  body {
    color: ${componentTokens.textIconSet.default};
  }

  a {
    /* NOTE: href属性がないaタグはpointerやunderlineが出ないので、明示的に指定 */
    ${linkBehabior}

    /* NOTE: 各componentで指定しているcolorを上書きしないため、classNameの指定がないaタグにのみ適用 */
    &:not([class]) {
      ${linkColor}
    }
  }

  input:focus,
  textarea:focus {
    outline: none;

    ${boxShadowStyles.outerNormal};
  }

  /* NOTE: 印刷時にスクロールバーが表示されないようにする */
  ${mediaQueries.print} {
    body: {
      overflow-y: hidden;
    }
  }
`;
