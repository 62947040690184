import React from 'react';
import TimeeIcon from '~/assets/logo/with-icon.svg';
import * as styles from './index.styles';

export const TESTID = {
  CONTAINER: 'CONTAINER',
} as const;

export type Props = {
  title?: string | React.ReactNode;
  children: React.ReactNode;
  showHeaderLogo?: boolean;
  hiddenFooterLogo?: boolean;
  footerLink?: {
    title: string;
    href: string;
  };
  themeColorBg?: boolean;
  titleColorAlert?: boolean;
};

export const LayoutSingleAction: React.FC<Props> = (props) => (
  <div css={[styles.bg, props.themeColorBg && styles.yellowBg]}>
    <div css={styles.container}>
      {props.showHeaderLogo && (
        <div css={styles.headerLogo.container}>
          <img src={TimeeIcon} alt="タイミー" css={styles.headerLogo.item} />
        </div>
      )}

      {props.title && (
        <h1 css={[styles.title, props.titleColorAlert && styles.titleAlert]}>{props.title}</h1>
      )}
      <div data-testid={TESTID.CONTAINER}>{props.children}</div>
    </div>

    {props.footerLink && (
      <div css={styles.footerLink.container}>
        <a href={props.footerLink.href}>{props.footerLink.title}</a>
      </div>
    )}

    {!props.hiddenFooterLogo && <img css={styles.footerLogo} src={TimeeIcon} alt={''} />}
  </div>
);
