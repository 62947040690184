import React from 'react';
import Head from 'next/head';
import { applicationConfig } from '~/constants/applicationConfig';

type IUseCustomHead = (config: { title?: string }) => {
  render: () => JSX.Element;
};

export const useCustomHead: IUseCustomHead = (config) => {
  const renderCustomHead = () => (
    <Head>
      <title>
        {config.title
          ? `${config.title} | ${applicationConfig.serviceName}`
          : applicationConfig.serviceName}
      </title>
      <link href="/images/apple-touch-icon.png" rel="apple-touch-icon" sizes="152x152" />
      <link href="/images/favicon-32x32.png" rel="icon" sizes="32x32" type="image/png" />
      <link href="/images/favicon-16x16.png" rel="icon" sizes="16x16" type="image/png" />
      <meta content="#da532c" name="msapplication-TileColor" />
      <meta content="#ffffff" name="theme-color" />
      <meta name="robots" content="noindex, nofollow" />
    </Head>
  );

  return {
    render: renderCustomHead,
  };
};
