import { useEffect } from 'react';
import { envManager } from '~/constants/envManager';
import { localStorageKeys } from '~/constants/localStorageKeys';
import { useLocalStorage } from '~/hooks/common/useLocalStorage';
import { FeatureFlagType } from '~/types/featureFlag';
import { uniqArray } from '~/utils/uniqArray';

const INITIAL_FEATURES: string[] = [];

export const useDevelopmentFeatureFlagSetter = () => {
  const localStorageHook = useLocalStorage<string[]>(
    localStorageKeys.development.featureFlags,
    INITIAL_FEATURES,
  );

  const activate = (feature: FeatureFlagType) => {
    localStorageHook.set((prevState) => uniqArray([...prevState, feature]));
  };
  const deactivate = (feature: FeatureFlagType) => {
    localStorageHook.set((prevState) => prevState.filter((prevFeature) => prevFeature !== feature));
  };
  const clear = () => {
    localStorageHook.reset();
  };

  useEffect(() => {
    if (!process.browser) return;
    if (envManager.publicEnv.environment === 'production') return;

    window.featureFlag = {
      activate,
      deactivate,
      clear,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
