import React from 'react';
import { LayoutSingleAction, Props } from '~/components/layouts/LayoutSingleAction';
import { LayoutComponent } from '~/types/layout';

export const getSingleActionLayout = (args?: {
  getLayoutContents: () => Omit<Props, 'children'>;
}): LayoutComponent => {
  const Layout: LayoutComponent = ({ children }) => {
    return <LayoutSingleAction {...args?.getLayoutContents()}>{children}</LayoutSingleAction>;
  };
  return Layout;
};
