import { css, SerializedStyles } from '@emotion/react';
import { font, FontSizeName, FontWeightName } from './font';

export const textCss = (opt: {
  color?: string;
  weight?: FontWeightName;
  size?: FontSizeName;
  display?: 'block' | 'inline' | 'inline-block' | 'none';
}): SerializedStyles => css`
  ${opt.size && `font-size: ${font.size[opt.size]}px;`}
  ${opt.weight && `font-weight: ${font.weight[opt.weight]};`}
  ${opt.display && `display: ${opt.display};`}
  ${opt.color && `color: ${opt.color};`}
`;
