import React, { Fragment, useEffect } from 'react';
import Head from 'next/head';

type IUseChatbot = (options: { show: boolean }) => {
  render: () => React.ReactNode;
};

export const useChatbot: IUseChatbot = (option) => {
  const render = () => (
    <Fragment>
      {option.show && (
        <Head>
          <script
            src="https://timee.karakuri.ai/api/chats/popup.js"
            data-referrer="clients"
            async
            defer
          />
        </Head>
      )}
    </Fragment>
  );

  useEffect(() => {
    document
      .querySelectorAll<HTMLElement>("[class='krkr-button-img']")
      .forEach((element) => (element.style.display = option.show ? 'block' : 'none'));
    if (!option.show) {
      document.querySelectorAll<HTMLElement>("[class='krkr-chat']").forEach((element) => {
        element.style.display = 'none';
      });
    }
  }, [option.show]);

  return {
    render,
  };
};
