import { z } from 'zod';

export type EnvironmentVariable = z.infer<typeof envSchema>;

const envSchema = z.object({
  serverEnv: z.object({}),
  publicEnv: z.object({
    environment: z.enum(['production', 'staging', 'development', 'test']),
    targetDomain: z.enum(['client', 'admin']),
    applicationMode: z.enum(['working', 'under_maintenance']),
    apiBaseUrl: z.string().url(),
    gtmId: z.string().min(1),
    gtmAuth: z.string().min(1),
    gtmPreview: z.string().min(1),
    gitBranchName: z.string().min(1),
    versionKey: z.string().min(1),
  }),
});

export const validateEnv = envSchema.parse;

const env = validateEnv({
  serverEnv: {},
  publicEnv: {
    environment: process.env.NEXT_PUBLIC_ENV,
    targetDomain: process.env.NEXT_PUBLIC_TARGET_DOMAIN,
    applicationMode: process.env.NEXT_PUBLIC_APPLICATION_MODE,
    apiBaseUrl: process.env.NEXT_PUBLIC_API_BASE_URL,
    gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    gtmAuth: process.env.NEXT_PUBLIC_GTM_AUTH,
    gtmPreview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
    gitBranchName: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF,
    versionKey: process.env.NEXT_PUBLIC_VERSION_KEY,
  },
});

const getApiBaseUrl = (): string | null => {
  if (typeof window === 'undefined') {
    // サーバーサイドでの処理の場合
    return null;
  }

  const demoApiBaseUrlTemplate = process.env.NEXT_PUBLIC_DEMO_API_BASE_URL_TEMPLATE;
  if (!demoApiBaseUrlTemplate) {
    // 環境変数にdemo環境用テンプレートが存在しない場合
    return null;
  }

  const host = window.location.host;
  const parts = host.split('.');

  if (parts.length !== 4) {
    // 想定しているドメイン(app-new.demoX.stg-taimee.com)と異なる場合
    return null;
  }

  const demoLabel = parts[1].toLowerCase();

  // .env.stagingで定義されているDEMO_API_BASE_URL_TEMPLATEを使用してDemo環境のAPIのベースURLを生成する
  const demoApiBaseUrl = demoApiBaseUrlTemplate.replace('<DEMO_ENV>', demoLabel);

  return demoApiBaseUrl;
};

if (process.env.NEXT_PUBLIC_ENV === 'staging') {
  // ENV=stagingにはStaging環境とDemo環境が含まれる
  // demo環境用のAPIを取得できた場合は上書きする
  env.publicEnv.apiBaseUrl = getApiBaseUrl() ?? env.publicEnv.apiBaseUrl;
}

export const envManager = env;
