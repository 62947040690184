export const swrKeys = {
  fetchPagedOfferings: (page: number) => `fetchPagedOfferings/${page}`,
  fetchPagedOffers: (clientId: number, page: number, cacheKey: number, forGraduated?: boolean) =>
    `fetchPagedOffers/${clientId}/${cacheKey}/${page}/${forGraduated ? 1 : 0}`,
  fetchClientBase: `fetchClientBase`,
  fetchCompanyBase: 'fetchCompanyBase',
  fetchClientDetail: (clientId: number) => `fetchClientDetail/${clientId}`,
  fetchClientRestriction: (clientId: number) => `fetchClientRestriction/${clientId}`,
  fetchUserReviewsFromClient: (page: number, userId: number, clientId: number) =>
    `fetchUserReviewsFromClient/${page}/${userId}/${clientId}`,
  fetchUserWorkHistory: (page: number) => `fetchUserWorkHistory/${page}`,
  fetchUsers: (scope: { groupId: number; sortKey: string; sortDirection: string; page: number }) =>
    `fetchUsers/${scope.groupId}/${scope.sortKey}/${scope.sortDirection}/${scope.page}`,
  fetchGroups: 'fetchGroups',
  fetchGroupsForWorkerReview: 'fetchGroupsForWorkerReview',
  fetchGroupNames: 'fetchGroupNames',
  fetchOfferingsRelatedToOffer: (clientId: number, offeringId: number, page: number) =>
    `fetchOfferingsRelatedToOffer/${clientId}/${offeringId}/${page}`,
  fetchMatchingUsers: (offeringId: number) => `fetchMatchingUsers/${offeringId}`,
  fetchOffering: (offeringId: number) => `fetchOffering/${offeringId}`,
  fetchOfferingWage: (clientId: number) => `fetchOfferingWage/${clientId}`,
  fetchOfferDetail: (clientId: number, offerId: number) =>
    `fetchOfferDetail/${clientId}/${offerId}`,
  fetchUserDetail: (clientId: number, userId: number) => `fetchUserDetail/${clientId}/${userId}`,
  fetchUserBadges: (clientId: number, userId: number) => `fetchUserBadges/${clientId}/${userId}`,
  fetchOfferingsInPeriod: (clientId: number, from: Date | undefined, to: Date | undefined) =>
    !from || !to ? null : `fetchOfferingsInPeriod/${clientId}/${from}/${to}`,
  fetchOfferingFormDetail: (clientId: number, offerId: number, offeringId: number) => `
  fetchOfferingFormDetail/${clientId}/${offerId}/${offeringId}`,
  fetchSelectablePositions: (clientId: number, offerId: number) =>
    `fetchSelectablePositions/${clientId}/${offerId}`,
  fetchMultidatePostingOperations: (clientId: number) =>
    `fetchMultidatePostingOperations/${clientId}`,
  fetchUserEmergencyContact: (clientId: number, userId: number) =>
    `fetchUserEmergencyContact/${clientId}/${userId}`,
  fetchNoticeList: (page: number, categoryIds: number[]) =>
    `fetchNoticeList/${page}/${categoryIds.join(',')}`,
  fetchStatementsAsClient: (clientId: number, page: number) =>
    `fetchStatementsAsClient/${clientId}/${page}`,
  fetchNextBillingAmount: (clientId: number) => `fetchNextBillingAmount/${clientId}`,
  fetchFixPaymentRequests: (clientId: number, page: number) =>
    `fetchFixPaymentRequests/${clientId}/${page}`,
  fetchClientReviewsFromUsers: (clientId: number, page: number) =>
    `fetchClientReviewsFromUsers/${clientId}/${page}`,
  fetchClientReviewsEvaluationFromUsers: (clientId: number) =>
    `fetchClientReviewsEvaluationFromUsers/${clientId}`,
  fetchClientOverview: (companyId: number) => `fetchClientOverview/${companyId}`,
  fetchCompanyDetail: (companyId: number) => `fetchCompanyDetail/${companyId}`,
  fetchStatementsAsCompany: (companyId: number, page: number) =>
    `fetchStatementsAsCompany/${companyId}/${page}`,
  fetchWorkerListStorages: (companyId: number) => `fetchWorkerListStorages/${companyId}`,
  fetchSubscriptionClients: (companyId: number) => `fetchSubscriptionClients/${companyId}`, // NOTE: 将来削除する
  fetchChatRooms: (clientId: number) => `fetchChatRooms/${clientId}`,
  fetchChatRoom: (clientId: number, uid: string) => `fetchChatRoom/${clientId}/${uid}`,
  fetchAccount: (category: string) => `fetchAccount/${category}`,
  fetchInvitationCodeForGraduate: (clientId: number) =>
    `fetchInvitationCodeForGraduate/${clientId}`,
  fetchAccessibleClients: `fetchAccessibleClients`,
  fetchAccessibleCompanies: `fetchAccessibleCompanies`,
  fetchAccountSubscriptionClients: `fetchAccountSubscriptionClients`,
  fetchAccountNotificationCounts: `fetchAccountNotificationCounts`,
  confirmAuthenticated: 'confirmAuthenticated',
  fetchAccountSetupTokenStatus: 'fetchAccountSetupTokenStatus',
  fetchMasterCompanyOccupations: `fetchMasterCompanyOccupations`,
  fetchVersionKey: `fetchVersionKey`,
  fetchClientCompanyClassification: (clientId: number) =>
    `fetchClientCompanyClassification/${clientId}`,
  fetchAccountSignInStatus: `fetchAccountSignInStatus`,
  fetchTags: `fetchTags`,
  fetchOccupations: `fetchOccupations`,
  fetchClientTrackingData: (clientId: number) => `fetchClientTrackingData/${clientId}`,
  fetchOnboardingAnswers: (offeringId: number) => `fetchOnboardingAnswers/${offeringId}`,
  fetchGraduateCandidates: (clientId: number, page: number) =>
    `fetchGraduateCandidates/${clientId}/${page}`,
  fetchBusinessFactCheckList: `fetchBusinessFactCheckList`,
  fetchBusinessFactCheckDetail: (businessFactCheckId: number) => `
    fetchBusinessFactCheckDetail/${businessFactCheckId}`,
  fetchBusinessFactCheckJudge: (businessFactCheckId: number) => `
    fetchBusinessFactCheckJudge/${businessFactCheckId}`,
  fetchAdminUserRole: `fetchAdminUserRole`,
} as const;
