import { useState, useEffect, useCallback } from 'react';

// NOTE: 型の定義は JSON.parse の返り値の型に合わせている
// https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/JSON/parse#return_value
export type StorageableType =
  | string
  | number
  | boolean
  | undefined
  | null
  | StorageableType[]
  | { [key: string]: StorageableType };

export type IUseLocalStorage = <T extends StorageableType>(
  key: string,
  initialValue: T,
) => {
  value: T;
  loading: boolean;
  set: (value: T | ((prevState: T) => T)) => void;
  reset: () => void;
};

// 同期処理(localStorage)に問題があればlocalForage等に移行する
// https://usehooks.com/useLocalStorage/
export const useLocalStorage: IUseLocalStorage = <T extends StorageableType>(
  key: string,
  initialValue: T,
) => {
  const [storedValue, setStoredValue] = useState<T>(initialValue);
  const [loadingState, setLoadingState] = useState(true);
  const [isValueUpdated, setValueUpdated] = useState(false);

  useEffect(() => {
    try {
      const item = window.localStorage.getItem(key);
      if (item) {
        setStoredValue(JSON.parse(item));
      }
    } catch (_) {
      // 何もしない
    } finally {
      setLoadingState(false);
    }
  }, [key]);

  useEffect(() => {
    if (!isValueUpdated) return;

    try {
      window.localStorage.setItem(key, JSON.stringify(storedValue));
    } catch (_) {
      // 何もしない
    }
    setValueUpdated(false);
  }, [key, storedValue, isValueUpdated]);

  const set = useCallback((value: T | ((prevState: T) => T)) => {
    setStoredValue(value);
    setValueUpdated(true);
  }, []);

  const reset = useCallback(() => {
    setStoredValue(initialValue);
    window.localStorage.removeItem(key);
  }, [key, initialValue]);

  return {
    value: storedValue,
    loading: loadingState,
    set,
    reset,
  };
};
