import { css, SerializedStyles } from '@emotion/react';
import { layout } from '~/styles/layout';

const spaceStyle = (
  propertyName: 'margin' | 'padding',
  all?: number,
  xAxios?: number,
  yAxios?: number,
  right?: number,
  left?: number,
  top?: number,
  bottom?: number,
): string => {
  return `
    ${all ? `${propertyName}: ${layout.baseSize.margin * all}px;` : ''}
    ${
      xAxios
        ? `${propertyName}-left: ${layout.baseSize.margin * xAxios}px;
          ${propertyName}-right: ${layout.baseSize.margin * xAxios}px;`
        : ''
    }
    ${
      yAxios
        ? `${propertyName}-top: ${layout.baseSize.margin * yAxios}px;
          ${propertyName}-bottom: ${layout.baseSize.margin * yAxios}px;`
        : ''
    }
    ${right ? `${propertyName}-right: ${layout.baseSize.margin * right}px;` : ''}
    ${left ? `${propertyName}-left: ${layout.baseSize.margin * left}px;` : ''}
    ${top ? `${propertyName}-top: ${layout.baseSize.margin * top}px;` : ''}
    ${bottom ? `${propertyName}-bottom: ${layout.baseSize.margin * bottom}px;` : ''}
  `;
};

const lineHeightTable = {
  l: '1.4em',
  m: '1.2em',
  s: '1em',
};

const lineHeightStyle = (lineHeight?: 'l' | 'm' | 's') =>
  lineHeight ? `line-height: ${lineHeightTable[lineHeight]};` : '';

export const alignCss = (opt: {
  p?: number;
  px?: number;
  py?: number;
  pr?: number;
  pl?: number;
  pt?: number;
  pb?: number;
  m?: number;
  mx?: number;
  my?: number;
  mr?: number;
  ml?: number;
  mt?: number;
  mb?: number;
  lineHeight?: 'l' | 'm' | 's';
  align?:
    | 'start'
    | 'end'
    | 'left'
    | 'right'
    | 'center'
    | 'justify'
    | 'justify-all'
    | 'match-parent';
}): SerializedStyles => {
  return css`
    text-align: ${opt.align || 'start'};
    ${lineHeightStyle(opt.lineHeight)}
    ${spaceStyle('padding', opt.p, opt.px, opt.py, opt.pr, opt.pl, opt.pt, opt.pb)}
    ${spaceStyle('margin', opt.m, opt.mx, opt.my, opt.mr, opt.ml, opt.mt, opt.mb)}
  `;
};
