import { css } from '@emotion/react';

export const boxShadowStyles = {
  outerNormal: css`
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  `,
  innerNormal: css`
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25);
  `,
};
