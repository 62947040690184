import { detect, Browser } from 'detect-browser';

export type BrowserType = ReturnType<typeof detect>;

export const RecommendedBrowserLabels = [
  '最新版 Google Chrome',
  '最新版 Microsoft Edge',
  '最新版 Safari',
];

// NOTE: 以下から厳選
// https://github.com/DamonOehlman/detect-browser/blob/master/src/index.ts
const RECOMMENDED_BROWSERS: (Browser | 'node' | 'bot' | 'react-native')[] = [
  'edge-chromium',
  'chrome',
  'chromium-webview',
  'crios', // iOS Chrome
  'android',
  'ios',
  'safari',
  'ios-webview',
];
// 非推奨かつ2023/10以降にブロック予定のブラウザ
const BLOCK_BROWSERS: (Browser | 'node' | 'bot' | 'react-native')[] = ['ie'];

const usingRecommendEnv = (browser: BrowserType): boolean => {
  if (browser === null) return true;
  return RECOMMENDED_BROWSERS.includes(browser.name);
};

const usingBlockEnv = (browser: BrowserType): boolean => {
  if (browser === null) return false;
  return BLOCK_BROWSERS.includes(browser.name);
};

export const recommendBrowserMessage = (
  detectBrowser = detect,
):
  | {
      usingRecommend: true;
    }
  | {
      usingRecommend: false;
      usingBlockBrowser: boolean;
    } => {
  const browserInfo = detectBrowser();
  if (usingRecommendEnv(browserInfo)) return { usingRecommend: true };
  const usingBlockBrowser = usingBlockEnv(browserInfo);
  return {
    usingRecommend: false,
    usingBlockBrowser,
  };
};
