import { useEffect, useState } from 'react';
import axios from 'axios';
import useSWR from 'swr';
import { swrKeys } from '~/constants/swrKeys';
import { ResponseData } from '~/pages/api/version-key';

// NOTE: 4時間でリフレッシュしたい
const REFRESH_INTERVAL = 1000 * 60 * 60 * 4;

// NOTE: version-key確認リクエストが多そうだったので、revalidate間隔を少しだけ長くした
const DEDUPING_INTERVAL = 1000 * 60 * 10;

export const useWatchRevisionUp = (onVersionUp: Function) => {
  const [versionKey, setVersionKey] = useState<string | null>(null);
  const fetcher = () => axios.get<ResponseData>('/api/version-key');
  const { data } = useSWR(swrKeys.fetchVersionKey, fetcher, {
    refreshInterval: REFRESH_INTERVAL,
    dedupingInterval: DEDUPING_INTERVAL,
  });

  useEffect(() => {
    if (!data) return;
    const currentVersionKey = data.data.versionKey;

    // NOTE: 初期設定
    if (versionKey === null) {
      setVersionKey(currentVersionKey);
      return;
    }

    // NOTE: バージョンが更新されていたらonVersionUpを呼ぶ
    if (versionKey !== currentVersionKey) {
      setVersionKey(currentVersionKey);
      onVersionUp();
    }
  }, [data, onVersionUp, versionKey]);
};
