import { localStorageKeys } from '~/constants/localStorageKeys';
import { useLocalStorage } from '~/hooks/common/useLocalStorage';
import { FeatureFlagType } from '~/types/featureFlag';

const INITIAL_FEATURES: FeatureFlagType[] = [];

export const useFeatureFlagList = () => {
  const { value, loading: isLoading } = useLocalStorage<FeatureFlagType[]>(
    localStorageKeys.development.featureFlags,
    INITIAL_FEATURES,
  );

  return {
    activeFeatures: value,
    isLoading,
  };
};
