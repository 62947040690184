import React, { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { DevelopmentLabel } from '~/components/developments/DevelopmentLabel';
import { envManager } from '~/constants/envManager';
import { useFeatureFlagList } from '~/hooks/common/useFeatureFlagList';

// demoドメインからdemo環境を取得するための正規表現
const DemoRegExp = /\.(demo[0-9]+)\./;
// 漏洩防止対策のため、ブランチ名を表示するページのリスト
const BrachLabelPages = [
  '/admin_users',
  '/bulk_update_service_fee_operations',
  '/business_fact_checks',
  '/proposals',
] as const;

export const useEnvironmentViewer = () => {
  const { activeFeatures } = useFeatureFlagList();
  const [labelName, setLabelName] = useState('');
  const router = useRouter();

  useEffect(() => {
    if (envManager.publicEnv.environment === 'production' || !router.isReady) return;

    const isShowBranchName = BrachLabelPages.some((page) => router.pathname.startsWith(page));
    if (isShowBranchName) {
      setLabelName(envManager.publicEnv.gitBranchName);
    } else {
      const demoName = window.location.hostname.match(DemoRegExp)?.[1];
      setLabelName(demoName ?? envManager.publicEnv.environment);
    }
  }, [router]);

  const render = useCallback(() => {
    return (
      <>
        {envManager.publicEnv.environment !== 'production' && (
          <DevelopmentLabel labelName={labelName} flagList={activeFeatures} />
        )}
      </>
    );
  }, [activeFeatures, labelName]);

  return {
    render,
  };
};
