export const deepEqual = (
  prev: Record<string, string | number | boolean | null | undefined>,
  next: Record<string, string | number | boolean | null | undefined>,
): boolean => {
  if (prev === next) return true;

  const prevKeys = Object.keys(prev);
  const nextKeys = Object.keys(next);

  if (prevKeys.length !== nextKeys.length) return false;

  for (const key of prevKeys) {
    if (prev[key] !== next[key]) return false;
  }

  return true;
};
