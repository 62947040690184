import { css } from '@emotion/react';
import { componentTokens } from './designToken';

export const linkColor = css`
  color: ${componentTokens.text.link.default};
  &:hover {
    color: ${componentTokens.text.link.hover};
  }
  &:visited {
    color: #551a8b;
  }
`;

export const linkBehabior = css`
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-color: currentcolor;
  cursor: pointer;
`;

export const linkStyle = css`
  ${linkBehabior}
  ${linkColor}
`;
