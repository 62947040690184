import { useEffect } from 'react';

type IUseWarnSelfXss = () => void;

const processOn = (type: 'server' | 'browser'): boolean => {
  if (type === 'browser' && typeof window === 'object') return true;
  if (type === 'server' && typeof window !== 'object') return true;
  return false;
};

/* eslint no-console: "off" */
export const useWarnSelfXss: IUseWarnSelfXss = () => {
  useEffect(() => {
    if (!processOn('browser')) return;
    console.log('⚠️注意⚠️');
    console.log('これは開発者向けのブラウザ機能です。');
    console.log(
      'ここに何かをコピー・貼り付けするように言われた場合、それは第三者がアカウントへのアクセスを得るための詐欺または不正行為です。',
    );
    console.log('詳細は https://g.co/kgs/kfuyA5 をご覧ください。');
  }, []);
};
