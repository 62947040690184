import { useEffect, useState } from 'react';
import { deepEqual } from './deepEqual';
import type { GTMPayload, INIT_GTM } from './types';

const sendToGTM = (payload: GTMPayload) => {
  window.dataLayer.push(payload);
};

const useSetupInitializeVariables = (args: Partial<Omit<INIT_GTM, 'event'>>) => {
  const [prevArgs, setArgs] = useState(args);

  useEffect(() => {
    if (deepEqual(prevArgs, args)) return;

    setArgs(args);

    const { account_id, timee_client_id, company_id } = args;
    if (account_id === undefined) return;
    if (timee_client_id === undefined && company_id === undefined) return;

    sendToGTM({
      event: 'INIT_GTM',
      account_id,
      timee_client_id,
      company_id,
    });
  }, [args, prevArgs]);
};

export const useGTM = () => {
  return {
    sendToGTM,
    useSetupInitializeVariables,
  };
};
